/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import TestimonilaItem from '../../components/website/testimonialsItem';
import axios from 'axios';
import { Loader } from '../../components/website/Loader';

export default class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }

    async componentDidMount() {

        this.getAllCMS('Testimonials');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0&sortBy=order_id")
            .then(res => {
                console.log("Testimonials res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)


            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Testimonials"></SubHeader>
                <div className='pt-5 pb-5 testimonialsPage'>
                    <div className="container">
                        <div className="row">
                            {this.state.tableData === null ? <Loader></Loader> : this.state.tableData.map(item => {
                                return (
                                    <TestimonilaItem
                                        testType="boy"
                                        name={item.cms_title}
                                        desc={item.cms_description}
                                        course={item.cms_course_name}
                                        cms_image={item.cms_image}
                                    />
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
