/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import BlogItem from '../../components/website/elementItem';
import CourseImage from '../../images/course_image.jpg';
import axios from 'axios';
import LinkItem from "../../components/LinkItem";
import Autocomplete from '../../components/website/search';
import Placeholder from '../../images/placeholder.jpg'
import { Loader } from '../../components/website/Loader';

export default class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null,
            autSuggestion: [],
            cms_category: []
        };
    }


    async componentDidMount() {

        this.getAllCMS('Blog');
        this.getCategoriesByCMSType('Blog');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Blog res", res)

                const filteredDates = res.data.data.sort((a, b) => new Date(b.cms_publish_date) - new Date(a.cms_publish_date))
                console.log("filteredDates", filteredDates)

                this.setState({ tableData: filteredDates, loading: false, autSuggestion: filteredDates })


            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    async getCategoriesByCMSType(cms_type) {

        // console.log("getCategoriesByCMSType, cms_type", token, cms_type)


        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms_categories_by_cms_type/' + cms_type)
            .then(res => {
                // console.log("getCategoriesByCMSType res", res)

                this.setState({ cms_category: res.data.data })

            })
            .catch(error => {
                console.log("error.response.status", error)
                if (error.response.status === 400) {

                    console.log("API error", error);
                    console.log("error.response", error.response.data.message);

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                } else if (error.response.status === 401) {

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });

                } else {

                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                }
            });

    }

    render() {
        return (
            <React.Fragment>
                {/* <SubHeader title="Blog"></SubHeader> */}
                <div className='bannerBlog'>
                    <div className='inner'>
                        <h2>FinQuest Institute Blog</h2>
                        <p>Welcome to our blog page. Our blogs talk about risk management, quantitative analytics, regulations and fintech.</p>                                    </div>
                </div>
                <div className='wrapperContent'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-12 text-left">
                                {this.state.tableData === null ?  <Loader></Loader> : this.state.tableData.map(item => {
                                    return (
                                        <>
                                            <div className="row blogList mb-5">
                                                <div className='col-md-5 col-sm-12 text-left p-0'>
                                                    {item.cms_image == null ? <img src={Placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />}
                                                </div>
                                                <div className='col-md-7 col-sm-12 text-left'>
                                                    <div className='inner'>
                                                        <h2>{item.cms_title}</h2>
                                                        <LinkItem
                                                            to={`/view-blog-details/${item.cms_title.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"}
                                                            title={'Read More'}
                                                        >
                                                        </LinkItem>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }

                            </div>

                            <div className="col-md-3 col-sm-12 text-left">
                                <div className="blog-sidebar">
                                    <div className="item">
                                        <Autocomplete
                                            options={this.state.autSuggestion}
                                        />
                                    </div>
                                    <div className="item">
                                        <h5>Category</h5>
                                        <ul className="cat-list">
                                            {this.state.cms_category.map((item) => {
                                                return (
                                                    <li value={item.title}>
                                                        <LinkItem to={'/blog/' + item.title} title={item.title}></LinkItem> </li>
                                                );
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
